<template>
  <div>
    <v-row align="center">
      <template v-for="data in listProjects">
        <v-col :key="data.id" cols="12" xs="12" md="6" lg="6" xl="6">
          <v-overlay :value="loadingAll">
            <v-progress-circular :size="120" color="#466BE3" indeterminate>
              Cargando...
            </v-progress-circular>
          </v-overlay>

          <v-card
            style="height: 100%; border-radius: 15px"
            class="border-control-card-1 control-life-projects__desktop"
            outlined
            elevation="0"
          >
            <v-card-title>
              <v-layout justify-center>
                <img
                  alt="Imagen de control de vida"
                  class="mt-4"
                  :src="data.logo"
                  height="80"
                  width="80"
                />
              </v-layout>
            </v-card-title>
            <v-card-text style="height: 170px" class="text-left pb-0">
              <p class="ml-3 control__text-title">
                <strong> {{ data.title.rendered }} </strong>
              </p>
              <vue-show-more-text
                class="pb-0 pt-0 description-action"
                :text="data.descripcion"
                :lines="4"
                more-text="Ver más"
                less-text="Ver menos"
                additional-content-css="font-size:16px;"
                additional-content-expanded-css="font-size:16px;"
                additional-anchor-css="font-size: 16px;"
              />
            </v-card-text>
            <v-card-actions>
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="openDescription(data)"
                  block
                  rounded
                  style="color: #fff"
                  :style="{
                    'background-color': hover ? '#324CA1' : '#466BE3',
                  }"
                  class="mb-4 v-step-17 mt-6"
                >
                  Seleccionar
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>

          <v-card
            style="height: 80px"
            class="border-control-card-1 control-life-projects__mobile"
            outlined
          >
            <div class="container-img">
              <img :src="data.logo" class="img" />
            </div>
            <div class="text-left pb-0 container-title">
              <p class="title-project pl-2">
                <strong> {{ data.title.rendered }} </strong>
              </p>
            </div>
            <v-card-actions class="container-btn pr-2">
              <v-btn
                @click="goToProject(data.id)"
                rounded
                block
                dark
                class="btn-main v-step-17"
              >
                <strong> Ver </strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <!-- <v-alert
      v-show="show"
      class="text-left mt-4 text-center"
      icon="mdi-information-outline"
      prominent
      text
      type="info"
    >
      <p class="mb-0" style="font-size: 18px">
        Ya tiene un registro activo, pronto se contactará un padrino para
        asesorarle en su solicitud.
      </p>
    </v-alert> -->

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import vueShowMoreText from "vue-show-more-text";

export default {
  components: {
    vueShowMoreText,
  },
  props: ["act"],
  data() {
    return {
      loadingAll: false,
      listProjects: [],
      infoToken: {
        document: "",
      },
      show: false,
    };
  },
  computed: {
    statusControl() {
      let statusControl = this.$store.getters.getStatusControl;
      if (statusControl) {
        if (statusControl.flag) {
          this.show = false;
          this.listProjects = statusControl.projects;
          this.loadingAll = false;
        } else {
          this.show = true;
          this.listProjects = statusControl.projects;
          this.loadingAll = false;
        }
      } else {
        this.loadingAll = true;
      }
    },
  },
  methods: {
    openDescription(data) {
      this.$emit("open");
      this.$emit("info", data);
    },
    goToProject(id) {
      this.$router.push({
        name: "projectLife",
        params: { id },
      });
    },
  },
  watch: {
    statusControl: function (newCount, oldCount) {
      console.log("");
    },
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.border-control-card-1 {
  /* border: none !important;
  border-radius: 104px;
  border-color: #3b5bc0 !important; */

  background: #ffffff;
  border: 0.5px solid #d5dbff !important;
  border-radius: 15px;
}

.control__text-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #353535;
}

.description-action {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #626262;
}

.border-control-card-1:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

@media only screen and (min-width: 260px) {
  .control-life-projects__mobile {
    display: flex !important;
    padding: 3px;
    box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
      0px 8px 16px rgba(62, 123, 250, 0.16);
  }

  .container-img {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-title {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-btn {
    width: 30%;
    padding: 0 !important;
  }

  .img {
    width: 100%;
  }

  .title-project {
    margin-bottom: 0 !important;
    width: 100%;
    padding-left: 3px;
    color: #466be3;
    font-family: "AsapRegular";
    font-size: 1.15rem;
    font-weight: 200;
  }

  .control-life-projects__desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 425px) {
  .title-project {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1024px) {
  .control-life-projects__mobile {
    display: none !important;
  }

  .control-life-projects__desktop {
    display: block !important;
  }
}
</style>
