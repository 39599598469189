<template>
  <div>
    <v-dialog max-width="450" v-model="modal" persistent>
      <v-card>
        <v-card-title class="text-blue pb-0">
          <v-layout justify-center> {{ infoProject.name }} </v-layout>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <h3 class="mt-3 text-left">
              Escriba las razones por las que necesita el apoyo
            </h3>
            <v-textarea
              :rules="[
                (v) => !!v || 'Este campo es requerido',
                (v) =>
                  (v && v.length <= 2000) || 'supera el limite de caracteres',
              ]"
              class="mt-2 mb-5"
              label="Ingrese aquí la razón"
              counter="2000"
              rows="4"
              v-model="reason"
            ></v-textarea>
          </v-form>
          <v-row>
            <v-col cols="6">
              <v-btn @click="close" rounded block outlined color="#3957b9"
                >Cerrar</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingM"
                @click="save"
                rounded
                block
                style="color: #fff"
                color="#466BE3"
                class="modal-control-life__button"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["infoProject", "modal", "loadingM"],
  watch: {
    /*  modal: function () {
      this.$refs.form.reset();
    }, */
  },
  data() {
    return {
      reason: "",
    };
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("data", this.reason);
        this.$refs.form.reset();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  mounted() {},
};
</script>
<style>
.modal-control-life__button:hover {
  background-color: #324ca1 !important;
}
</style>
